export default function sectorManager() {
  return {
    selectedIndustry: "",
    selectedMcc: "",
    industries: {
      retail: "Retail",
      digital_products: "Digital Products",
      food_drinks: "Food & Drinks",
      professional_services: "Professional Services",
      personal_services: "Personal Services",
      transportation_travel: "Transportation and Travel",
      medical_services: "Medical Services",
      education: "Education",
      entertainment_recreation: "Entertainment and Recreation",
      building_services: "Building Services",
      financial_services: "Financial Services",
      regulated_products: "Regulated Products",
    },
    businessTypes: {
      Establishment: "establishment",
      Company: "company",
      Freelancer: "freelancer",
    },
    sectors: {
      retail: {
        5734: "Software",
        5651: "Clothing and Accessories",
        5411: "Convenience Stores",
        5977: "Beauty Products",
        5712: "Home Goods and Furniture",
        5732: "Home Electronics",
        5533: "Automotive Parts and Accessories",
        5944: "Jewelry Stores, Watches and Clocks",
        5094: "Precious Stones and Metals",
        5511: "Car Sales",
        5399: "Other Merchandise",
      },
      digital_products: {
        5734: "Software as a Service",
        5942: "Books",
        5735: "Music",
        7994: "Games",
        5815: "Other digital goods",
      },
      food_drinks: {
        5812: "Restaurants",
        5411: "Grocery stores",
        5811: "Caterers",
        5814: "Other food and dining",
      },
      professional_services: {
        7392: "Consulting",
        7338: "Printing and Publishing",
        8111: "Attorneys and Lawyers",
        8931: "Accounting & Auditing",
        7379: "Computer Repair",
        8734: "Testing Laboratories (Non-Medical)",
        7538: "Auto Services",
        5963: "Direct Marketing",
        4900: "Utilities",
        9399: "Government Aervices",
        5966: "Telemarketing",
        7393: "Other marketing services",
        7399: "Other business services",
      },
      personal_services: {
        7221: "Photography Studios",
        7298: "Health and Beauty Spas",
        7230: "Salons or Barbers",
        "0780": "Landscaping Services",
        7297: "Counseling Services",
        8099: "Health and Wellness Coaching",
        7211: "Laundry or Cleaning Services",
        7299: "Other Personal Services",
      },
      transportation_travel: {
        3355: "Car Rentals",
        4121: "Taxis, Limos and Ridesharing",
        4215: "Courier Services",
        7523: "Parking Lots",
        4722: "Travel Agencies",
        4214: "Shipping or Freight Forwarders",
        4111: "Commuter Transportation",
        4411: "Cruise Lines",
        4511: "Airlines and Air Carriers",
        6513: "Property Rentals",
        3514: "Hotels, Inns, or Motels",
        4789: "Other transportation services",
        7011: "Other travel and Accommodation",
      },
      medical_services: {
        5047: "Medical Devices",
        8011: "Doctors and Physicians",
        8043: "Opticians and Eyeglasses",
        8021: "Dentists and Orthodontists",
        8041: "Chiropractors",
        8050: "Nursing Facilities",
        8062: "Hospitals or Medical Organizations",
        "0742": "Veterinary Services",
        8099: "Other Medical Services",
      },
      education: {
        8351: "Child Care Services",
        8220: "Colleges or Universities",
        8241: "Vocational Schools or Trade schools",
        8211: "Elementary or Secondary Schools",
        8299: "Other Educational Services",
      },
      entertainment_recreation: {
        7922: "Event Ticketing",
        7991: "Tourist Attractions",
        7032: "Recreational Camps",
        7929: "Musicians, Bands, or Orchestras",
        7996: "Amusement Parks, Carnivals, or Circuses",
        7832: "Movie Theaters",
        7999: "Other Entertainment and Recreation",
      },
      building_services: {
        1520: "General Contractors",
        1731: "Electrical Contractors",
        1750: "Carpentry Contractors",
        1799: "Special Trade Contractors",
        4814: "Telecom Services",
        5065: "Telecom Equipment",
        1711: "A/C and Heating Contractors",
        1799: "Other Building Services",
      },
      financial_services: {
        6300: "Insurance",
        6010: "Currency Exchanges",
        6012: "Loans or Lending",
        7322: "Collections Agencies",
        6211: "Investment Services",
        6540: "Digital Wallets",
        6011: "Other Financial Institutions",
      },
      regulated_products: {
        5912: "Pharmacies or Pharmaceuticals",
        5499: "Supplements or Nutraceuticals",
        5999: "Other Regulated Products",
      },
    },
		init(industry, mcc) {
      this.selectedIndustry = industry || "retail"; // Default to "Retail"
      if (this.sectors[this.selectedIndustry]) {
        this.selectedMcc = mcc || Object.keys(this.sectors[this.selectedIndustry])[0];
      } else {
        this.selectedMcc = "";
      }
    }
  };
}
