import simulateTyping from './stimulate-typing';

export default function paymentAiPopup() {
	return {
		showPopup: false,
		explanationText: '',

		openPaymentAiModal(fullText) {
			this.showPopup = true;
			simulateTyping(this, fullText);
		},

		closePopup() {
			this.showPopup = false;
		}
	};
}
