export default function pricingManager() {
  return {
    init() {
      this.setupForm();
    },
    setupForm() {
      const price = document.getElementById("pricing-form");
      const checkbox = price.querySelector("input[type=checkbox]");
      price.addEventListener("submit", (event) => {
        if (!checkbox.checked) {
          event.preventDefault();
          alert("You must agree to the pricing terms to continue.");
        }
      });
    },
  };
}
