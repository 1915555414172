import { DateTime } from "luxon";

export default function dateToUtc(oldvalue = "", includeTime = true, minDate = null) {
  return {
    init() {
      this.$nextTick(() => {
        this.initDatePicker();
      });

      if (this.isValidDate(oldvalue)) {
        this.expired_at = DateTime.fromISO(oldvalue).toFormat(
          includeTime ? "yyyy-MM-dd HH:mm" : "yyyy-MM-dd"
        );
      } else {
        this.expired_at = "";
      }
    },
    expired_at: "",
    isValidDate(dateString) {
      const date = new Date(dateString);
      return !isNaN(date.getTime());
    },
    initDatePicker() {
      const el = this.$refs.createInvoicePicker;
      if (el) {
        const config = {
          defaultDate: this.expired_at,
          enableTime: includeTime,
          time_24hr: includeTime,
          minDate: minDate || null,
        };
        if (!includeTime) {
          delete config.enableTime;
          delete config.time_24hr;
        }
        this.flatpickrInstance = flatpickr(el, config);
      }
    },
    get zulu_expired_at() {
      try {
        return DateTime.fromFormat(
          this.expired_at,
          includeTime ? "yyyy-MM-dd HH:mm" : "yyyy-MM-dd"
        )
          .toUTC()
          .toISO();
      } catch (e) {
        console.log(e);
        return "";
      }
    },
  };
}