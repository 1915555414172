  export default function activationManager() {
    return {
      businessType: '',
      authorizedSignatoryType: '<%= @activation.authorized_signatory ? (@activation.authorized_signatory["national_id"] ? "valid" : "invalid") : "invalid" %>',
      businessPattern: '',
      authorizedSignatoryPattern: '',
      selectedJob: '',
      ibanPattern: '^[A-Z]{2}\\d{2}[A-Z0-9]{1,30}$',
      businessTypes: {
        establishment: 'Establishment',
        company: 'Company',
        freelancer: 'Freelancer',
      },
      activityLicenses: [{ number: '', document: '' }], // Initialize with one default entry
      investmentLicenses: [{ number: '', document: '' }], // Initialize with one default entry for investment licenses
      init(businessType) {
        console.log('init', this.businessType)
        this.businessType = businessType || 'establishment';
        this.updateAuthorizedSignatoryPattern();
        this.updateVatPattern();
        this.updateSelectedJob();
        this.isJobFieldDisabled()
        this.$watch('businessType', value => {
          this.updateVatPattern();
        });
        this.$watch('authorizedSignatoryType', value => {
          this.updateAuthorizedSignatoryPattern();
        });
      },
      updateAuthorizedSignatoryPattern() {
        this.authorizedSignatoryPattern = '^[12]\\d{9}$';
      },
      updateVatPattern() {
        this.vatPattern = '^3\\d{9}00003$';
      },
      updateSelectedJob() {
        if (this.businessType === 'freelancer') {
          this.selectedJob = 'owner';
        }
      },
      isJobFieldDisabled() {
          console.log('businessType', this.businessType)
        return this.businessType === 'freelancer';
      },
      addActivityLicense() {
        this.activityLicenses.push({
          number: '',
          document: ''
        });
      },
      removeActivityLicense(index) {
        if (this.activityLicenses.length > 1) {
            this.activityLicenses.splice(index, 1);
        }
      },
      addInvestmentLicense() {
        this.investmentLicenses.push({
          number: '',
          document: ''
        });
      },
      removeInvestmentLicense(index) {
        if (this.investmentLicenses.length > 1) {
            this.investmentLicenses.splice(index, 1);
        }
      }
    }
  }
