import Alpine from "alpinejs";
import persist from "@alpinejs/persist";
import focus from "@alpinejs/focus";
import mask from '@alpinejs/mask';
import globalState from './global-state';

import otpResend from "./components/resend-otp";
import dateToUtc from "./components/date-to-utc";
import apiKeyCopy from "./components/api-key-copy";
import metadataValidator from "./components/metadata-validator";
import noticeManager from "./components/notice-manager";
import metadataAdder from "./components/metadata-adder";
import ipAdder from "./components/ip-adder";
import profilePicture from "./components/profile-picture";
import currencies from "./components/currencies";
import imagePopup from "./components/image-popup";
import queryManager from "./components/query-manager";
import paymentAiPopup from "./components/payment-ai-popup";
import simulateTyping from "./components/stimulate-typing";

import ResourceFilter from "./components/filters/resource_filter";
import KeywordFilter from "./components/filters/keyword_filter";
import KeyValueFilter from "./components/filters/key_value_filter";
import BooleanFilter from "./components/filters/boolean_filter";
import SelectFilter from "./components/filters/select_filter";
import MultiselectFilter from "./components/filters/multiselect_filter";
import DateFilter from "./components/filters/date_filter";
import NumericFilter from "./components/filters/numeric_filter";
import SortFilter from "./components/filters/sort_filter";
import sectorManager from "./components/sector-manager";
import activationManager from "./components/activation-manager";
import pricingManager from "./components/pricing-manager";
// Plugins
Alpine.plugin(persist);
Alpine.plugin(focus);
Alpine.plugin(mask);

// Global State
Alpine.data('globalState', globalState);

// General Components
Alpine.data("noticeManager", noticeManager);
Alpine.data("ipAdder", ipAdder);
Alpine.data("metadataAdder", metadataAdder);
Alpine.data("otp_resend", otpResend);
Alpine.data("apiKeyCopy", apiKeyCopy);
Alpine.data("profilePicture", profilePicture);
Alpine.data("currencies", currencies);
Alpine.data("metadataValidator", metadataValidator);
Alpine.data("dateToUtc", dateToUtc);
Alpine.data("imagePopup", imagePopup);
Alpine.data("queryManager", queryManager);
Alpine.data("paymentAiPopup", paymentAiPopup);
Alpine.data("simulateTyping", simulateTyping);

// Filters
Alpine.data("resource_filter", ResourceFilter);
Alpine.data("keyword_filter", KeywordFilter);
Alpine.data("boolean_filter", BooleanFilter);
Alpine.data("select_filter", SelectFilter);
Alpine.data("multiselect_filter", MultiselectFilter);
Alpine.data("date_filter", DateFilter);
Alpine.data("numeric_filter", NumericFilter);
Alpine.data("sort_filter", SortFilter);
Alpine.data("key_value_filter", KeyValueFilter);

// Register for acgtivation
Alpine.data("sectorManager", sectorManager);
Alpine.data("activationManager", activationManager);
Alpine.data("pricingManager", pricingManager);

window.Alpine = Alpine;

document.addEventListener('DOMContentLoaded', () => Alpine.start());
