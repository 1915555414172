export default function simulateTyping(targetObj, fullText, callback) {
	let i = 0;
	targetObj.explanationText = '';  

	const typeCharacter = () => {
		if (i < fullText.length) {
			targetObj.explanationText += fullText[i];
			i++;

			const randomDelay = Math.floor(Math.random() * 30) + 10;
			setTimeout(typeCharacter, randomDelay);
		} else if (callback) {
			callback(); 
		}
	};

	typeCharacter();
}
