
export default function queryManager(userId, entityId) {
  return {
    open: false,
    userKey: `chatHistory_${userId}`,
    chatHistory: JSON.parse(localStorage.getItem(`chatHistory_${userId}`)) || {
      latest: [],
      previous: [],
    },
    messages: [],
    newMessage: "",
    sessionId: null,
    loading: false,
    disableSend: false,
    daysToKeep: 5,
    loadingPreviousChat: false,
    startingNewChat: false,
    newChatStarted: false,
    canLoadPreviousChat: false,

    init() {
      this.sessionId = this.generateSessionId();
      this.messages = this.chatHistory.latest || [];
      this.cleanupOldMessages();
      this.observeMessages();
    },

    shiftEnterCheck(event) {
      if (event.shiftKey) {
        const cursorPos = event.target.selectionStart;
        this.newMessage = this.newMessage.slice(0, cursorPos) + '\n' + this.newMessage.slice(cursorPos);
        this.$nextTick(() => {
          event.target.selectionStart = event.target.selectionEnd = cursorPos + 1;
        });
      } else {
        this.sendMessage();
      }
    },

    adjustTextareaHeight(event) {
      const textarea = event.target;
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    },

    generateSessionId() {
      return localStorage.getItem(`sessionId_${userId}`) || this.uuidv4();
    },

    uuidv4() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },

    checkSendMessage(event) {
      if (event.shiftKey && event.key === 'Enter') {
        this.newMessage += '\n';
      } else if (event.key === 'Enter') {
        this.sendMessage();
      }
    },

    async sendMessage() {
      if (this.newMessage.trim() === "" || this.disableSend) return;
      const messageText = this.newMessage;
      this.addMessage(messageText, "user");
      this.newMessage = "";
      this.loading = true;
      this.disableSend = true;

      try {
        const response = await fetch(`/entities/${entityId}/query_assistants`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": document
              .querySelector('meta[name="csrf-token"]')
              .getAttribute("content"),
          },
          body: JSON.stringify({
            session_id: this.sessionId,
            prompt: messageText,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          const aiMessageIndex = this.messages.length;
          this.messages.push({ text: "", type: "ai" });
					this.simulateTyping(aiMessageIndex, data.message);

					
          if (data.session_id) {
            this.sessionId = data.session_id;
          }
        } else {
          this.handleError(aiMessageIndex);
        }
      } catch (error) {
        console.error("Error:", error);
        this.handleError(aiMessageIndex);
      } finally {
        this.loading = false;
        this.disableSend = false;
      }
    },

		simulateTyping(index, fullText) {
      let i = 0;

      const typeCharacter = () => {
        if (i < fullText.length) {
          this.messages[index].text += fullText[i];
          this.updateMessageElement(index);
          i++;

          const randomDelay = Math.floor(Math.random() * 30) + 10;

          setTimeout(typeCharacter, randomDelay);
        } else {
          this.updateLocalStorage();  // Save after typing is complete
        }
      };

      typeCharacter();
		},

    handleError(index) {
      this.messages[index].text = "Sorry, something went wrong. Please try again later.";
      this.updateMessageElement(index);
    },

    addMessage(text, type) {
      if (typeof text !== 'string' || text.trim() === "") return;

      const newMessage = {
        text,
        type,
        timestamp: this.getFormattedTimestamp(),
        date: new Date().toISOString(),
      };

      this.messages.push(newMessage);
      this.updateLocalStorage();

      this.$nextTick(() => {
        const messageContainer = this.$el.querySelector("#query");
        if (messageContainer) {
          const messageElement = document.createElement("div");
          messageElement.className = `message ${type}`;
          messageElement.innerHTML = this.formatMessage(text);
          messageContainer.appendChild(messageElement);
          this.scrollToBottom();
        } else {
          console.error("Message container not found");
        }
      });
    },

    getFormattedTimestamp() {
      return new Date().toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      });
    },

    updateMessageElement(index) {
      this.$nextTick(() => {
        const messageContainer = this.$el.querySelector("#query");
        const messageElements = messageContainer.querySelectorAll(".message");
        if (messageElements[index]) {
          messageElements[index].innerHTML = this.formatMessage(this.messages[index].text);
          this.scrollToBottom();
        }
      });
    },

    formatMessage(message) {
      const segments = message.split(/(```[\s\S]*?```|`[\s\S]*?`)/g);
    
      const formattedSegments = segments.map(segment => {
        if (segment.startsWith('```') && segment.endsWith('```')) {
          const codeBlockContent = segment.slice(3, -3).trim();
          const lines = codeBlockContent.split('\n');
          const firstLine = lines[0];
    
          let language = 'plaintext';
          let code = codeBlockContent;
    
          if (/^\w+$/.test(firstLine)) {
            language = firstLine;
            code = lines.slice(1).join('\n');
          }
    
          const highlightedCode = hljs.highlight(language, code.trim()).value;
          return `<pre><code class="hljs ${language}">${highlightedCode}</code></pre>`;
        }
    
        if (segment.startsWith('`') && segment.endsWith('`')) {
          const inlineCodeContent = escapeHTML(segment.slice(1, -1).trim());
          return `<code class="bg-gray-100 dark:bg-gray-800 p-1 rounded">${inlineCodeContent}</code>`;
        }
    
        segment = segment.replace(/^(#{1,6})\s+(.*)$/gm, (match, hashes, text) => {
          const level = hashes.length;
          return `<h${level} class="mt-4 mb-2 text-lg font-bold text-gray-900 dark:text-white">${text}</h${level}>`;
        });
    
        return segment
          .replace(/(\n){3,}/g, "\n\n") 
          .replace(/(?<!<\/code>)\n(?!<code>)/g, "<br>") 
          .replace(/(\*\*|__)(.*?)\1/g, '<strong>$2</strong>')
          .replace(/(\*|_)(.*?)\1/g, '<em>$2</em>') 
          .replace(/&lt;/g, '<') 
          .replace(/&gt;/g, '>'); 
      });
    
      return formattedSegments.join('');
    },    

    scrollToBottom() {
      const messagesDiv = this.$el.querySelector("#query");
      if (messagesDiv) {
        messagesDiv.scrollTop = messagesDiv.scrollHeight;
      } else {
        console.error("Message container not found");
      }
    },

    observeMessages() {
      const messagesDiv = document.querySelector("#query");
      const observer = new MutationObserver(() => {
        this.scrollToBottom();
      });

      if (messagesDiv) {
        observer.observe(messagesDiv, { childList: true });
      } else {
        console.error("Message container not found for MutationObserver");
      }
    },

    updateLocalStorage() {
      this.chatHistory.latest = this.messages;
      localStorage.setItem(this.userKey, JSON.stringify(this.chatHistory));
    },

    cleanupOldMessages() {
      const now = new Date();
      this.chatHistory.latest = this.chatHistory.latest.filter((message) => {
        const messageDate = new Date(message.date);
        const diffTime = Math.abs(now - messageDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays <= this.daysToKeep;
      });

      this.chatHistory.previous = this.chatHistory.previous.filter(
        (message) => {
          const messageDate = new Date(message.date);
          const diffTime = Math.abs(now - messageDate);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          return diffDays <= this.daysToKeep;
        }
      );

      this.updateLocalStorage();
    },

    startNewSession() {
      if (this.newChatStarted) return;
      this.startingNewChat = true;
      this.newChatStarted = true;
      this.canLoadPreviousChat = true;

      this.chatHistory.previous = this.chatHistory.latest;
      this.chatHistory.latest = [];

      this.sessionId = null;
      localStorage.removeItem(`sessionId_${userId}`);

      this.messages = [];
      this.updateLocalStorage();
      this.startingNewChat = false;
    },

    loadPreviousMessages() {
      if (!this.canLoadPreviousChat) return;
      this.loadingPreviousChat = true;
      this.newChatStarted = false;
      this.canLoadPreviousChat = false;
      this.messages = this.chatHistory.previous || [];
      this.updateLocalStorage();

      this.$nextTick(() => {
        this.scrollToBottom();
        this.loadingPreviousChat = false;
      });
    },
  };
}

function escapeHTML(html) {
  return html
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}
